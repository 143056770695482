import React from 'react'
import { Link } from 'gatsby'
import { Wrapper, Grid, H, P } from '@farewill/ui'

import DefaultLayout from 'layouts/DefaultLayout'
import ExternalLink from 'components/ExternalLink'
import TelephoneNumber from 'components/TelephoneNumber'
import PATHS from 'paths'
import { formattedOpenHours, formattedOpenDays } from 'lib/time/formatting'
import { WILLS } from 'lib/products/constants'
import {
  GENERAL_EMAIL_ADDRESS,
  GENERAL_TELEPHONE_NUMBER,
  LEGAL_EMAIL_ADDRESS,
} from 'config'

const Contact = () => (
  <DefaultLayout
    title="Contact Us"
    description={`We’re here to help ${formattedOpenDays(
      WILLS
    )}, ${formattedOpenHours(WILLS)}`}
  >
    <Grid container>
      <Grid.Item spanFromM={9} spanFromL={6}>
        <Wrapper separator>
          <H size="XL" decorative>
            Talk to us
          </H>
          <P>
            We always like to hear from our customers to understand if there’s
            anything we can do better. If you’ve got any feedback, we’d love to
            hear from you. Please email us at{' '}
            <ExternalLink href={`mailto:${GENERAL_EMAIL_ADDRESS}`}>
              {GENERAL_EMAIL_ADDRESS}
            </ExternalLink>
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Help or legal questions</H>
          <P>
            For general help, email us at{' '}
            <ExternalLink href={`mailto:${GENERAL_EMAIL_ADDRESS}`}>
              {GENERAL_EMAIL_ADDRESS}
            </ExternalLink>{' '}
            or phone us on{' '}
            <TelephoneNumber
              underline
              telephoneNumber={GENERAL_TELEPHONE_NUMBER}
              showIcon={false}
              strong={false}
            />
            .
          </P>
          <P>
            For legal questions questions email us at{' '}
            <ExternalLink href={`mailto:${LEGAL_EMAIL_ADDRESS}`}>
              {LEGAL_EMAIL_ADDRESS}
            </ExternalLink>
            .
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Security related enquiries</H>
          <P>
            If you have any security concerns or think you’ve found a
            vulnerability with our site, please head to our{' '}
            <Link to={PATHS.GENERAL.SECURITY}>security</Link> and{' '}
            <Link to={PATHS.GENERAL.SECURITY_REPORTING}>
              security reporting
            </Link>{' '}
            pages.
          </P>
        </Wrapper>

        <Wrapper separator>
          <H size="M">Press</H>
          <P>
            For press enquiries, see our{' '}
            <Link to={PATHS.GENERAL.PRESS}>press page</Link>.
          </P>
        </Wrapper>
      </Grid.Item>
    </Grid>
  </DefaultLayout>
)

export default Contact
